.OwnerDetails-List {
  display: grid;
  gap: 32px 40px;
  grid-template-columns: auto 1fr;
  word-wrap: anywhere;
}

.OwnerDetails-Detail {
  display: contents;
}

.OwnerDetails-Description {
  margin: 0;
}

@media (width <= 1023px) {
  .OwnerDetails-List {
    grid-template-columns: 1fr;
  }

  .OwnerDetails-Detail {
    display: grid;
    gap: 8px;
  }
}
