.Select {
  &.Select_native {
    padding-top: 0;
    padding-right: 2rem;
    padding-bottom: 0;
    appearance: none;
    text-overflow: ellipsis;

    &::-ms-expand {
      display: none;
    }
  }

  &.is-disabled {
    .Select-arrow-zone {
      cursor: default;
      pointer-events: none;
    }
  }

  &.is-searchable.is-open {
    .Select-control {
      cursor: text;
    }
  }
}

.Select,
.SelectHolder {
  position: relative;
}

.SelectHolder {
  display: inline-block;
  width: 100%;
}

.SelectIcon {
  position: absolute;
  top: 0;
  right: 0.5em;
  bottom: 0;
  margin: auto;
  pointer-events: none;
}

.Select-control {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: inherit;
  border-radius: 0.5rem;
  cursor: default;
  font-size: inherit;
  outline: none;
}

.Select-placeholder {
  line-height: 1.4;
  opacity: 0.5;

  .has-value & {
    opacity: 1;
  }
}

.Select-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0.2em 0.75em;
  background: transparent;
  font: inherit;
  line-height: 1.4;

  > input {
    padding: 0;
    border: 0 transparent;
    margin: 0;
    background: transparent;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: 1.4;
    outline: none;

    &:focus {
      outline: none;
    }
  }
}

.Select-value {
  position: absolute;
  overflow: hidden;
  width: calc(100% - 2em);
  line-height: 1.4;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Select-menu-outer {
  position: absolute;
  z-index: 1000;
  top: calc(100% - 0.125rem);
  right: -0.0625rem;
  left: -0.0625rem;
  max-height: 12em;
  box-sizing: border-box;
  padding-top: 0.125rem;
  border: 0.0625rem solid var(--background-border);
  border-color: inherit;
  border-top-color: transparent;
  margin-top: -0.0625rem;
  background-color: var(--background-default);
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  color: var(--content-on-background-default);
}

.Select-menu {
  max-height: 12em;
  max-height: calc(12em - 0.125rem * 2);
  overflow-y: auto;
}

.Select-option {
  display: block;
  box-sizing: border-box;
  padding: 0.325em 0.75em;
  border-bottom: 1px solid var(--background-border);
  cursor: pointer;
  transition:
    background-color var(--animation-speed-default) var(--animation-easing-default),
    color var(--animation-speed-default) var(--animation-easing-default);

  &:last-child {
    border-bottom: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &.is-focused {
    background-color: var(--primary-surface-85);
    color: var(--content-on-color-default);
    transition:
      background-color var(--animation-speed-zero) var(--animation-easing-default),
      color var(--animation-speed-zero) var(--animation-easing-default);
  }

  &:active {
    background-color: var(--primary-action);
    color: var(--content-on-color-default);
    transition:
      background-color var(--animation-speed-zero) var(--animation-easing-default),
      color var(--animation-speed-zero) var(--animation-easing-default);
  }

  &.is-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.Select-noresults,
.Select-search-prompt,
.Select-searching {
  display: block;
  box-sizing: border-box;
  padding: 0.25em 0.5em;
  cursor: default;
  opacity: 0.5;
}

.Select-clear-zone {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 0.25em 0.75em;
  text-align: center;
}

.Select_disabledValue {
  color: var(--content-on-background-minor) !important;
}

.Select_disabledValue option:not(:disabled) {
  color: var(--content-on-background-default) !important;
}
